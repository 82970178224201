<template>
  <div class="main">
    <HeaderTitle></HeaderTitle>

    <div class="box">
      <div class="c-box">
        <div class="person-box">
          <div class="person-head">
            <p class="qz-box">建议反馈</p>
              <div class="form-list" @click="dialogList">我的反馈</div>
          </div>
          <el-form :model="form" label-position="top" style="padding: 0 20px" class="suggestions">

            <el-form-item label="反馈内容" label-width="120px">
              <el-input
                type="textarea"
                placeholder="请简要描述您的问题和意见，一遍我们提供更好的帮助"
                v-model="form.content"
                maxlength="300"
                show-word-limit
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>

            <el-form-item label="图片上传" label-width="120px">
              <el-upload
                name="uploadfile"
                :action="$global.uploadAction"
                list-type="picture-card"
                :file-list="fileList"
                :limit="5"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="save">提 交</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="反馈记录"
      :modal="false"
      :visible.sync="dialogListShow"
      width="70%"
      lock-scroll
    >
    <!-- :before-close="handleClose" -->
      <div v-if="feedbackList.length > 0" class="feedback-box">
        <div
          class="feedback"
          v-for="(item, index) in feedbackList"
          :key="index"
        >
          <div>反馈内容:</div>
          <div class="feed-content">{{ item.content }}</div>
          <div style="padding-top:10px">反馈图片:</div>
          <div v-if="item.img" class="feed-imgList">
            <img
              class="feed-img"
              :src="image"
              v-for="(image, idx) in item.img.split(',')"
              :key="idx"
              alt=""
            />
          </div>
          <div>反馈回复:</div>
          <div class="feed-content" v-if="item.feedbackList.length > 0">
            {{ item.feedbackList[0].content }}
          </div>
        </div>
      </div>
      <el-empty v-else description="空空如也"></el-empty>
      <el-pagination
        v-if="feedbackList.length > 0"
        background
        layout="prev, pager, next"
        :total="totalRows"
        :current-page="totalList"
        :page-size="5"
        @current-change="chenPagination"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/hometitle/hometitle.vue";
import {
  jobFeedbackAdd,
  jobFeedbackPage,
} from "@/api/personalCenter/personalCenter";
export default {
  name: "",
  data() {
    return {
      form: {
        createUser: "", //反馈用户id
        content: "", //内容
        img: "", //图片,逗号分隔
      },
      dialogListShow: false,
      fileList: [],
      urls: [],
      feedbackList: [],
      totalList: 1,
      totalRows: 0,
      params: {
        pageNo: 1,
        pageSize: 5,
      },
    };
  },
  props: {},
  components: {
    HeaderTitle,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    save() {
      let arr = [];
      this.urls.forEach((i) => arr.push(i.response.msg));
      this.form.img = arr.join(",");
      this.form.createUser = localStorage.getItem("userId");
      if(!this.form.content){
        this.$message.error("请填写反馈信息");
        return
      }
      jobFeedbackAdd(this.form).then((res) => {
        if (res.success) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.$router.push("/home");
        } else {
          this.$message.error("提交失败，请重试");
        }
      });
    },
    dialogList() {
      this.dialogListPage();
      this.dialogListShow = !this.dialogListShow;
    },
    dialogListPage() {
      let createUser = localStorage.getItem("userId");
      this.params.createUser = createUser;
      jobFeedbackPage(this.params).then((res) => {
        console.log(res);
        if (res.success) {
          this.feedbackList = res.data.rows;
          this.totalRows = res.data.totalRows;
        }
      });
    },
    chenPagination(cur) {
      this.params.pageNo = cur;
      this.dialogListPage();
    },
    handleAvatarSuccess(res, file, fileList) {
      console.log(res, "res");
      console.log(file, "file");
      console.log(fileList, "fileList");
      if (res) {
        this.urls.push(file);
      }
    },
    handleRemove(file) {
      this.urls = this.urls.filter((i) => i.url != file.url);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  // margin: 30px auto 0;
  .box {
    width: 900px;
    height: 700px;
    background-color: #ededed;
    margin: 100px  auto  0;
    border-radius:10px;
    .c-box {
      .person-box {
      }
      .message-box {
      }
      .qz-box  {
        color: #808080;
        margin-left: 15px;
        font-size: 15px;
      }
    }
  }

  .dialog-footer {
    // display: flex;
    // justify-content: ;
    text-align: right;
    margin-right: 10%;
  }
}
.person-head {
  display: flex;
  justify-content: space-between;
  .form-list {
    margin-right: 30px;
    padding-top: 70px;
    color: #029aff;
    cursor: pointer;
  }
}
.feedback-box{
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 20px;
  .feedback{
    margin-bottom: 20px;
    background-color: #f5f5f5;
    box-sizing: border-box;
    padding: 10px 20px;
    .feed-content{
      text-indent: 60px;
      padding-top: 10px;
    }
    .feed-img{
      width: 120px;
      height: 120px;
      margin: 15px 15px 15px 0;

    }
  }
}
.feedback-box::-webkit-scrollbar {
    width: 0 !important;
  }
  .feedback-box {
    -ms-overflow-style: none;
  }
  .feedback-box {
    overflow: -moz-scrollbars-none;
  }
.p-list::-webkit-scrollbar {
  display: none;
}
::v-deep .el-textarea__inner::-webkit-scrollbar {
  display: none;
}
::v-deep .el-textarea__inner {
  // border: none !important;
  font-size: 16px;
}

.message-list-box::-webkit-scrollbar {
  display: none;
}
</style>
